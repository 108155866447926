import React, { useEffect, useState } from "react";
// import Tab from "@mui/material/Tab";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import './customModal.css';
import withRouter from "../Router/withRouter";
import "../Auth/Orders.css";
import { Drawer, Box, List, ListItem, Typography } from "@mui/material";
import { BsInfoCircleFill } from "react-icons/bs";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

import Pagination from "@mui/material/Pagination";
import {
  Table,
  TableBody,
  // TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Stack,
} from "@mui/material";
// import Stack from "@mui/material/Stack";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { GrFormNextLink } from "react-icons/gr";
import Cart from "./cart";
import { FaPlus, FaMinus, FaCheck } from "react-icons/fa";

import {
  getProducts,
  products,
  emptyCart,
} from "../../services/Actions/client";
import ModalPop from "./ModalPop";
import NoItems from "../../Components/no_items";
// import

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Orders(props) {
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [value, setValue] = useState("");
  const [isFirst, setFirst] = useState(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    setRows(props.products);
    const newRows = props.products.filter((item) => item.qty != 0);
    setSelectedRows(newRows);
    setFirst(true);
  }, [props]);

  const handleQuantityChange = (index, change, item) => {
    let { dispatch } = props;

    const newRows = rows.map((row) =>
      row.id === item.id
        ? { ...row, qty: Math.max(1, row.qty + change), isUpdate: true }
        : row
    );

    dispatch({
      type: "GET_PRODUCTS",
      data: newRows,
    });

    setRows(newRows);
  };
  const onFocusHandle = (e, row) => {
    if (e.target.value === "") {
      const newRows = rows.map((r) =>
        r.id === row.id ? { ...r, qty: 0, isUpdate: true } : r
      );

      props.dispatch({
        type: "GET_PRODUCTS",
        data: newRows,
      });

      setRows(newRows);
    }
  };
  const handleInputChange = (event, row) => {
    const newQuantity =
      event.target.value == ""
        ? ""
        : Math.max(0, Number(event.target.value) || 0);

    const newRows = rows.map((r) =>
      r.id === row.id ? { ...r, qty: newQuantity, isUpdate: true } : r
    );

    props.dispatch({
      type: "GET_PRODUCTS",
      data: newRows,
    });

    setRows(newRows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const onSearch = (e) => {
    let value = e.target.value;
    setValue(value);
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const DrawerList = (
    <Box
      sx={{
        width: 250,
        padding: "16px",
        backgroundColor: "#1C1C1C",
        height: "100%",
      }}
      role="presentation"
    >
      <Typography
        variant="h5"
        sx={{ color: "#fff" }}
        style={{
          padding: "16px",
          fontSize: "30px",
          fontWeight: "600",
          paddingBottom: "0px !important",
          marginBottom: "0px !important",
        }}
      >
        Insights
      </Typography>
      <List>
        {["Lorem Ipsum", "Lorem Ipsum", "Lorem Ipsum", "Lorem Ipsum"].map(
          (text, index) => (
            <ListItem
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6" sx={{ color: "#fff" }}>
                  {text}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#aaa", marginBottom: "8px" }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod.
                </Typography>
                <a
                  sx={{ color: "#F79250", padding: 0, textTransform: "none" }}
                  href="#"
                  style={{ textDecorationLine: "underline", color: "#F79250" }}
                >
                  Read More &gt;
                </a>
              </Box>
              <Box
                sx={{
                  width: "280px",
                  height: "150px",
                  backgroundColor: "#C4C4C4",
                  marginLeft: "16px",
                  borderRadius: "10px",
                }}
              />
            </ListItem>
          )
        )}
      </List>
    </Box>
  );

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when rows per page changes
  };

  return (
    <div className="w-full">
      <div className="container commoncontainer">
        <div className="row">
          <div className="productssection">
            <div className="col-lg-12 d-flex align-items-center justify-content-between productstitleandsuggestionbuttonsection">
              <div className="d-flex align-items-center">
                <h3 className="mb-0">Products</h3>
                <span
                  className="ms-1 mb-1"
                  // onClick={toggleDrawer(true)}
                >
                  <BsInfoCircleFill color="grey" />
                </span>
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                  className="insightdrawer"
                >
                  {DrawerList}
                </Drawer>
              </div>
              <ModalPop />
            </div>
            {rows.length > 0 && (
              <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5 ordercontainerdivsection">
                <div className="inputseachsection">
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                    onChange={onSearch}
                  />
                </div>
              </div>
            )}
            {rows.length > 0 ? (
              <TableContainer
                component={Paper}
                className={`table commontablesection ordertablesection`}
                // className={`table-responsive commontablesection ${selectedRows.length>0?"":"mb-0"}`}
                style={{ borderRadius: "0px" }}
              >
                <Table
                  className="table table-bordered"
                  style={{ marginBottom: "0px", border: "1px solid black" }}
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Product</StyledTableCell>
                      <StyledTableCell align="center">Unit</StyledTableCell>
                      <StyledTableCell align="center">Quantity</StyledTableCell>
                      <StyledTableCell align="center">
                        Availability
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .filter((item) =>
                        value
                          ? item.name
                              .toLowerCase()
                              .includes(value.toLowerCase())
                          : true
                      )
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map((row, index) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.unit_name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div className="productquantity d-flex align-items-center justify-content-center position-relative">
                              <span>
                                <FaMinus
                                  onClick={() =>
                                    handleQuantityChange(index, -1, row)
                                  }
                                  style={{
                                    cursor:
                                      row.qty > 1 ? "pointer" : "not-allowed",
                                    color: row.qty > 1 ? "black" : "grey",
                                  }}
                                />
                              </span>
                              <span>
                                <input
                                  type="number"
                                  className="quantityinputsection"
                                  value={row.qty}
                                  onChange={(e) => handleInputChange(e, row)}
                                  onBlur={(e) => onFocusHandle(e, row)}
                                  // onFocusCapture={}
                                  min="1"
                                />
                              </span>
                              <span>
                                <FaPlus
                                  onClick={() =>
                                    handleQuantityChange(index, 1, row)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                              {row.isUpdate && (
                                <span className="orderchecksection">
                                  <FaCheck style={{ color: "green" }} />
                                </span>
                              )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.delivery_window}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoItems />
            )}
            {/* Mobile Card Layout */}
            <div className="mobile-view-cards">
              {rows
                .filter((item) =>
                  value
                    ? item.name.toLowerCase().includes(value.toLowerCase())
                    : true
                )
                .map((row, index) => (
                  <div key={row.id} className="mobile-card">
                    <h4>{row.name}</h4>
                    <p>
                      <strong>Unit:</strong> {row.unit_name}
                    </p>
                    <div className="d-flex align-items-center">
                      <p>
                        <strong>Quantity:</strong>
                      </p>
                      <div className="productquantity d-flex align-items-center justify-content-start ms-3 position-relative">
                        <span>
                          <FaMinus
                            onClick={() => handleQuantityChange(index, -1, row)}
                            style={{
                              cursor: row.qty > 1 ? "pointer" : "not-allowed",
                              color: row.qty > 1 ? "black" : "grey",
                            }}
                          />
                        </span>
                        <span>
                          <input
                            type="number"
                            className="quantityinputsection"
                            value={row.qty}
                            onChange={(e) => handleInputChange(e, row)}
                            onBlur={(e) => onFocusHandle(e, row)}
                            min="1"
                          />
                        </span>
                        <span>
                          <FaPlus
                            onClick={() => handleQuantityChange(index, 1, row)}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                        {row.isUpdate && (
                          <span className="orderchecksection">
                            <FaCheck style={{ color: "green" }} />
                          </span>
                        )}
                      </div>
                    </div>
                    <p>
                      <strong>Availability:</strong> {row.delivery_window}
                    </p>
                  </div>
                ))}
            </div>
            <div className="myorderscontainerdivsection">
              <Stack
                spacing={2}
                className="d-flex justify-content-between align-items-center flex-row"
              >
                <div className="col-6">
                  <FormControl variant="outlined" size="small">
                    <InputLabel>Rows per page</InputLabel>
                    <Select
                      value={rowsPerPage}
                      onChange={handleRowsPerPageChange}
                      label="Rows per page"
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <Pagination
                    count={Math.ceil(
                      rows.filter((item) =>
                        value
                          ? item.name
                              .toLowerCase()
                              .includes(value.toLowerCase())
                          : true
                      ).length / rowsPerPage
                    )}
                    page={page}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </Stack>
            </div>
            {rows.length > 0 && selectedRows.length > 0 && (
              <div className="col-lg-12 d-flex justify-content-between viewshoppingcartandemptyshoppingcartsection align-items-center">
                <div>
                  <button
                    className="nav-button-section"
                    onClick={() => props.dispatch(emptyCart(rows))}
                  >
                    Empty Shopping Cart
                  </button>
                </div>
                <div className="viewshoppingcartsection">
                  <h5
                    onClick={() =>
                      props.router.navigate("/dashboard/1", { replace: true })
                    }
                  >
                    <a href>View Shopping Cart</a>
                    <span className="nextbuttonicon">
                      <GrFormNextLink />
                    </span>
                  </h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    products: state.product.products ?? [],
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));

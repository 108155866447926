import React, { useEffect, useState, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";

function AnalysisSideChart(props) {
  const [option, setOptions] = useState({});
  const chartRef = useRef(null); // Reference for the chart container

  useEffect(() => {
    
    let { horizontalConsumption, horizontalExpenditure, type } = props;

    // Step 1: Extract all unique product names
    let data =
      type === "Consumption"
        ? JSON.parse(JSON.stringify(horizontalConsumption))
        : JSON.parse(JSON.stringify(horizontalExpenditure));

    const productTotals = {};

    for (const day in data) {
      data[day].forEach((item) => {
        if (!productTotals[item.name]) {
          productTotals[item.name] = 0;
        }
        productTotals[item.name] += item.percentage || 0;
      });
    }

    // Step 2: Sort products by total percentage and pick top 7
    const topProducts = Object.entries(productTotals)
      .sort((a, b) => b[1] - a[1]) // Sort by total percentage in descending order
      .slice(0, 7) // Take the top 7 products
      .map((entry) => entry[0]); // Extract the product names

    // Step 3: Create the series data for each of the top 7 products
    const series = topProducts.map((product) => {
      const productData = [];
      for (const day in data) {
        const dayData = data[day].find((item) => item.name === product);
        productData.push(dayData 
          ? { 
            percentage: dayData.percentage || 0, // Add percentage as the value
              value: dayData.value || 0 // Add the total value as well
            }
          : { 
              value: 0, 
              percentage: 0 
            }); // Use 0 if the product is not available on that day
      }
      return {
        name: product,
        type: "bar",
        stack: "total",
        label: { show: true,
          formatter: (params) => {
            const { value, percentage } = params.data; // Destructure the value and total
            return `${percentage.toFixed(2)}%`; // Customize label text
          },
         },
        emphasis: { focus: "series" },
        data: productData,
      };
    });

    // Step 4: Create the final chart configuration
    const chartConfig = {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: { type: "value"},
      yAxis: {
        type: "category",
        data: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        axisLabel: {
          formatter: (value) => value,
          clickable: true,
        },
      },
      series: series,
    };

    setOptions(chartConfig);

    // Scroll to top of the container after rendering
    if (chartRef.current) {
      console.log("top scroll")
      chartRef.current.scrollTop = 0;
    }
  }, [props]);

  const onYAxisClick = (params) => {
    let { type } = props;

    const daySelected = params.name; // The day that was clicked
    props.router.navigate(`/RaceChart/${type}/${daySelected}`);
  };

  // Event object to capture yAxis click event
  const onEvents = {
    click: onYAxisClick,
  };

  return (
    <div
      className="d-flex justify-content-center"
      ref={chartRef} // Reference for scrolling
      style={{ overflowY: "auto", height: "600px", width: "100%" }}
    >
      <ReactEcharts option={option} style={{ height: "600px", width: "100%" }} onEvents={onEvents} />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    horizontalConsumption: state.invoice.horizontalConsumption,
    horizontalExpenditure: state.invoice.horizontalExpenditure,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnalysisSideChart));

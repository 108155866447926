import React, { useState, useEffect } from "react";
// import Tab from "@mui/material/Tab";

import { connect } from "react-redux";
import withRouter from "../Router/withRouter";

import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { LuDownload } from "react-icons/lu";
import { ContentHeader, Header } from "../../Components/header";
// import Calendar from "../Auth/calendar";
import DatePicker from "react-datepicker";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

import { getMyOrders, downloadMyOrder } from "../../services/Actions/client";
import MyOrdersModal from "./myOrdersModal";
import NoItems from "../../Components/no_items";
import EmptyMyOrders from "../../assets/cart/emptymyorders.webp";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function MyOrders(props) {
  const [myOrders, setMyOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [tableFilter, setTableFilter] = useState(7);

  const [openModal, setOpenModal] = useState(false); // State to control modal
  const [selectedOrder, setSelectedOrder] = useState(null); // State to store selected order details

  useEffect(() => {
    // getMyordersApi("","");
    // props.dispatch(getMyOrders("",""));
    callDropDownFilter(tableFilter);
  }, []);

  useEffect(() => {
    setMyOrders(props.myOrders);
  }, [props]);
  const getMyordersApi = (from, to) => {
    props.dispatch(getMyOrders(from, to));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
  };

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOrder(null);
  };

  const handleMonthChange = (date, setDate) => {
    setDate(date);
  };
  const clearDate = () => {
    setStartDate(null);
    setEndDate(null);
  };
  const applyFilter = (date1, date2) => {
    const from = date1.format("YYYY-MM-DD");

    const to = date2.format("YYYY-MM-DD");

    getMyordersApi(from, to);
  };

  const downloadOrder = (i) => {
    props.dispatch(downloadMyOrder(i));
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when rows per page changes
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // useEffect(() => {
  //   const today = new Date();
  //   const tomorrow = new Date();
  //   tomorrow.setDate(today.getDate() + 1);

  //   setFromDate(formatDate(today));
  //   setToDate(formatDate(tomorrow));
  // }, []);

  const setDefaultDate = () => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    setFromDate(formatDate(today));
    setToDate(formatDate(tomorrow));
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFromdateChange = (e) => {
    const newFromDate = e.target.value;
    setFromDate(newFromDate);

    if (new Date(newFromDate) >= new Date(toDate)) {
      const updatedTodate = new Date(newFromDate);
      updatedTodate.setDate(updatedTodate.getDate() + 1);
      setToDate(formatDate(updatedTodate));
    }
  };

  const handleTodateChange = (e) => {
    const newToDate = e.target.value;
    setToDate(newToDate);

    if (new Date(newToDate) <= new Date(fromDate)) {
      const updatedFromdate = new Date(newToDate);
      updatedFromdate.setDate(updatedFromdate.getDate() - 1);
      setFromDate(formatDate(updatedFromdate));
    }
  };

  const onClickApply = () => {
    console.log("Date Range", fromDate, toDate);
    applyFilter(moment(fromDate), moment(toDate));
  };
  const handleOptionsChange = (event) => {
    let val = event.target.value;
    if (val == 0) {
      // handleCloseModal();
      setDefaultDate();
    } else {
      callDropDownFilter(val);
      // setTableFilter(val);
    }
    setTableFilter(val);
  };
  const callDropDownFilter = (val) => {
    const today = moment();
    const laststart = today.clone().subtract(val, "days");
    applyFilter(laststart, today);
  };

  return (
    <div className="w-full">
      <ContentHeader />
      <Header />
      <div className="container myorderscommoncontainer">
        <div className="row">
          <div className="productssection">
            <div className="col-lg-12 d-flex align-items-center justify-content-between myorders-header-section">
              <h3 className="col-lg-6">My Orders</h3>
              <div className="col-lg-2">
                <FormControl variant="outlined" size="medium" fullWidth={true}>
                  {/* <InputLabel>Rows per page</InputLabel> */}
                  <Select
                    value={tableFilter}
                    onChange={handleOptionsChange}
                    sx={{
                      height: 36, // Adjust the height here (e.g., 36px)
                      fontSize: "0.875rem", // Optional: Adjust font size for consistent appearance
                    }}
                    // label="Rows per page"
                  >
                    <MenuItem value={7}>Last 7 days</MenuItem>
                    <MenuItem value={14}>Last 2 weeks</MenuItem>
                    <MenuItem value={30}>Last 30 days</MenuItem>
                    <MenuItem value={0}>Custom Range</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {tableFilter == 0 && (
              <div className="reservation-box col-lg-12">
                <div className="static common-div-section">
                  <div className="input-container h-25">
                    <label htmlFor="fromdate">From Date</label>
                    <input
                      type="date"
                      id="fromdate"
                      value={fromDate}
                      onChange={handleFromdateChange}
                    />
                  </div>
                </div>
                <div className="flex common-div-section">
                  <div className="input-container h-25">
                    <label htmlFor="todate">To Date</label>
                    <input
                      type="date"
                      id="todate"
                      value={toDate}
                      onChange={handleTodateChange}
                    />
                  </div>
                </div>
                <div className="flex">
                  <button className="button-section greyButton">Clear</button>
                </div>
                <div className="flex">
                  <button onClick={onClickApply} className="button-section">
                    Apply
                  </button>
                </div>
              </div>
            )}

            {myOrders.length > 0 ? (
              <TableContainer
                className="commontablesection"
                component={Paper}
                style={{ borderRadius: "0px" }}
              >
                <Table
                  className="table table-bordered mb-0"
                  style={{ border: "1px solid black" }}
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Order Number</StyledTableCell>
                      <StyledTableCell align="center">
                        Date Placed
                      </StyledTableCell>
                      <StyledTableCell align="center">Status</StyledTableCell>
                      <StyledTableCell align="center">
                        Order Summary
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myOrders
                      .filter((item) =>
                        search
                          ? item.order_id
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            item.status
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          : true
                      )
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map((row) => (
                        <StyledTableRow key={row.order_number}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.order_number}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.created_at}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div className="statussection delivered">
                              {row.status}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <button
                              className="viewmodalbutton"
                              onClick={() => handleOpenModal(row)}
                            >
                              View
                            </button>
                            <span
                              onClick={() => downloadOrder(row)}
                              className="pl-2 download-icon"
                            >
                              <LuDownload height={60} width={60} />
                            </span>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="emptycartdivsection">
                <img
                  src={EmptyMyOrders}
                  alt="image"
                  className="emptycartimagesection"
                />
                <div className="emptycartcontentsection">
                  <h3>
                    Looks Like You Have <span>No Orders</span>
                  </h3>
                  <h5>
                  It’s the perfect time to start shopping and create your order list.
                  </h5>
                  {/* <button>Add Order</button> */}
                </div>
              </div>
            )}
            {/* {myOrders.length>0&&<div className="col-lg-12 d-flex align-items-center justify-content-between myorderscontainerdivsection"> */}
            {/* <div className="inputseachsection"> */}
            {/* <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search by Date..."
                  aria-label="Search"
                  onChange={onSearch}
                /> */}
            {/* </div> */}
            {myOrders.length > 0 && (
              <div className="myorderscontainerdivsection">
                <Stack
                  spacing={2}
                  className="d-flex justify-content-between align-items-center flex-row"
                >
                  {/* Dropdown for Rows Per Page */}
                  <div className="col-6">
                    <FormControl variant="outlined" size="small">
                      <InputLabel>Rows per page</InputLabel>
                      <Select
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        label="Rows per page"
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <Pagination
                      count={Math.ceil(
                        myOrders.filter((item) =>
                          search
                            ? item.order_id
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              item.status
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            : true
                        ).length / rowsPerPage
                      )}
                      page={page}
                      onChange={handleChangePage}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </Stack>
              </div>
            )}
            {/* </div>} */}
          </div>
        </div>
      </div>
      {selectedOrder && (
        <MyOrdersModal
          order={selectedOrder}
          open={openModal}
          handleClose={handleCloseModal}
        />
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    myOrders: state.product.myOrders ?? [],
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyOrders)
);

import React, { useState, useEffect } from "react";
import AnalysisChart from "./AnalaysisChart";
import "./analysis.css";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AnalysisPie from "./AnalysisPie";
import AnalysisSideChart from "./AnalysisSideChart";
import moment from "moment";
// import { setMonth } from 'date-fns';
import {
  getBarchartData,
  getPiechartData,
  gethorizontalChartData,
} from "../../services/Actions/client";

function Analysis(props) {
  const [selected, setSelected] = useState("Consumption");
  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    const previousMonthDate = new Date(
      now.getFullYear(),
      now.getMonth() - 1,
      now.getDate()
    );
    return previousMonthDate;
  });
  const [endDate, setEndDate] = useState(new Date());

  const handleChange = (id) => {
    setSelected(id);
    const monthName = moment(startDate).format("MMMM");
    const year = moment(startDate).format("YYYY");

    getChartDetails(id, monthName, year);
  };
  useEffect(() => {
    //     const currentDate = moment(startDate);
    // const previousMonthDate = currentDate.subtract(1, 'months');
    const monthName = moment(startDate).format("MMMM");
    // setStartDate(previousMonthDate)
    const year = moment(startDate).format("YYYY");

    getChartDetails(selected, monthName, year);
  }, []);

  const getChartDetails = (item, month, year) => {
    let { dispatch } = props;
    // const monthName = moment(startDate).format('MMMM');

    dispatch(getBarchartData(item.toLowerCase(), month, year));
    dispatch(getPiechartData(item.toLowerCase(), month, year));
    dispatch(gethorizontalChartData(item.toLowerCase(), month, year));
  };

  const handleMonthChange = (date) => {
    // let sf= date.getMonth();
    const monthName = moment(date).format("MMMM");
    const year = moment(startDate).format("YYYY");

    setStartDate(date); // Set only the month
    getChartDetails(selected, monthName, year);
  };
  return (
    <div className="w-full">
      <div className="container commoncontainer">
        <div className="row">
          <div>
            <h3 className="analysisTitle">Analysis</h3>
          </div>
          <div className="mt-5">
            <div className="d-flex justify-content-start gap-3 align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col">
                {/* <Form.Select
                  aria-label="Default select example"
                  className="defaultFormSelect"
                >
                  <option>Select Month</option>
                  <option value="1">Jan</option>
                  <option value="2">Feb</option>
                  <option value="3">Mar</option>
                </Form.Select> */}
                <DatePicker
                  selected={startDate}
                  onChange={(date) => handleMonthChange(date)}
                  onYearChange={(date) => handleMonthChange(date)}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  className="form-control"
                />
              </div>

              {/* <div className="col-2">
                 <DatePicker
                  selected={endDate}
                  onChange={(date) => handleMonthChange(date, setEndDate)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  className="form-control"
                />
              </div> */}
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 d-flex align-items-center">
              <div className="col-6">
                <Form.Check
                  type="radio"
                  label="Consumption"
                  id="radio1"
                  value="Consumption"
                  checked={selected === "Consumption"}
                  onChange={() => handleChange("Consumption")}
                  className={`custom-radio radioboxtextsection ${
                    selected === "Consumption" ? "selected" : ""
                  }`}
                />
              </div>
              <div className="col-6">
                <Form.Check
                  type="radio"
                  label="Expenditure"
                  id="radio2"
                  checked={selected === "Expenditure"}
                  onChange={() => handleChange("Expenditure")}
                  className={`custom-radio radioboxtextsection ${
                    selected === "raExpendituredio2" ? "selected" : ""
                  }`}
                />
              </div>
              </div>
            </div>
          </div>
          <div>
            <AnalysisChart type={selected} />
          </div>

          <div>
            <h5 className="text-center normaltextsection">
              Average {selected.toLowerCase()} of products per day
            </h5>
          </div>

          <div>
            <AnalysisPie type={selected} />
          </div>

          <div>
            <h5 className="text-center normaltextsection">
              Average {selected.toLowerCase()} of particular type of products
              per day
            </h5>
          </div>

          <div>
            <AnalysisSideChart type={selected} />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    dayConsumption: state.invoice.dayConsumption,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Analysis)
);

import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import moment from "moment";
// import TableRow from "@mui/material/TableRow";
// import { styled } from "@mui/material/styles";
import Product1 from "../../assets/cart/carrot.jpg";
import Product2 from "../../assets/cart/greenpepper.jpg";
import Product3 from "../../assets/cart/beans.jpg";
import { GrFormPreviousLink } from "react-icons/gr";
import { FaPlus, FaMinus } from "react-icons/fa";
import { emptyCart, placeOrders } from "../../services/Actions/client";
import NoItems from "../../Components/no_items";
import EmptyCart from "../../assets/cart/emptycart.png";
// import EmptyCart from "../../assets/cart/emptycart2.webp";
// import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

import carrotImage from "../../assets/cart/carrot.jpg";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

function createData(name, unit, initialQuantity, availability) {
  return { name, unit, initialQuantity, availability };
}

const initialRows = [
  createData("Carrots", "Kg", 1, "24 hrs delivery"),
  createData("Green Bell Pepper", "Packets", 1, "24 hrs delivery"),
  createData("Beans", "Packets", 1, "24 hrs delivery"),
];

function Carts(props) {
  const tomorrowDate = moment().add(1, "days").format("dddd, MM/DD/YY");
  const [rows, setRows] = useState(initialRows);
  const [allRows, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [value, setValue] = useState("");

  useEffect(() => {
    let list = props.products;
    const newRows = list.filter((item) => item.qty != 0);
    setRows(newRows);
    setProducts(list);
  }, [props]);

  const handleQuantityChange = (index, change, item) => {
    let { dispatch } = props;
    const newRows = allRows.map((row, i) =>
      row.id === item.id ? { ...row, qty: row.qty + change } : row
    );
    dispatch({
      type: "GET_PRODUCTS",
      data: newRows,
    });
  };
  // const handleQuantityChange = (index, change) => {
  //   const newRows = [...rows];
  //   const newQuantity = newRows[index].qty + change;
  //   if (newQuantity >= 1) {
  //     newRows[index].qty = newQuantity;
  //     setRows(newRows);
  //   }
  // };
  const removeItem = (item) => {
    let { dispatch } = props;
    const newRows = allRows.map((row, i) =>
      row.id === item.id ? { ...row, qty: 0 } : row
    );
    dispatch({
      type: "GET_PRODUCTS",
      data: newRows,
    });
  };
  const onSearch = (e) => {
    let value = e.target.value;
    setValue(value);
  };
  const getProductImage = (index) => {
    switch (index) {
      case 0:
        return Product1;
      case 1:
        return Product2;
      case 2:
        return Product3;
      default:
        return Product1;
    }
  };
  const getTotalQty = () => {
    let quantity = 0;
    rows.map((i) => {
      quantity = quantity + i.qty;
    });
    return quantity;
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const initiateOrders = async () => {
    let { dispatch } = props;
    let products = [];
    rows.map((i) => {
      products.push({
        product_id: i.id,
        quantity: i.qty,
      });
    });
    let payload = {
      products: products,
    };

    let res = await dispatch(placeOrders(payload)).then(() => {
      props.router.navigate("/MyOrders");
    });

    if (res) {
      // dispatch(emptyCart());
    }
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when rows per page changes
  };

  return (
    <div className="w-full">
      {/* <ContentHeader />
      <Header />
      <Dashboards /> */}
      <div className="container commoncontainer">
        <div className="row">
          <div className="productssection">
            <div className="col-lg-12 mb-3">
              <h3>Cart</h3>
            </div>
            {rows.length > 0 && (
              <div className="col-lg-12 d-flex align-items-center justify-content-between cartcontainerdivsection">
                <div className="inputseachsection">
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                    onChange={onSearch}
                  />
                </div>
              </div>
            )}
            {rows.length > 0 ? (
              <div className="totalproductdetailssection">
                {rows
                  .filter((item) =>
                    value
                      ? item.name.toLowerCase().includes(value.toLowerCase())
                      : true
                  )
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .map((row, index) => (
                    <div
                      className="productdetailssection col-lg-12 d-flex align-items-center justify-content-between"
                      key={row.name}
                    >
                      <div className="d-flex align-items-center my-2">
                        <div onClick={() => removeItem(row)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#D9D9D9"
                            className="bi bi-x-circle closeiconsection"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                        </div>
                        <div>
                          <img
                            src={row.image_url}
                            // src={carrotImage}
                            alt="img"
                            className="productimagesection"
                          ></img>
                        </div>
                        <div className="productdescription">
                          <div>
                            <h3>{row.name}</h3>
                          </div>
                          <div>
                            <h5>Units: {row.unit_name}</h5>
                            <h5>24 hrs delivery</h5>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="modalproductquantity d-flex align-items-center justify-content-center">
                          <span>
                            <FaMinus
                              className="minusiconsection"
                              onClick={() =>
                                handleQuantityChange(index, -1, row)
                              }
                              style={{
                                cursor: row.qty > 1 ? "pointer" : "not-allowed",
                                color: row.qty > 1 ? "black" : "grey",
                              }}
                            />
                          </span>
                          <span>
                            <input
                              type="text"
                              className="quantityinputsection"
                              value={row.qty}
                              readOnly
                            />
                          </span>
                          <span>
                            <FaPlus
                              className="plusiconsection"
                              onClick={() =>
                                handleQuantityChange(index, 1, row)
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="emptycartdivsection">
                <img
                  src={EmptyCart}
                  alt="image"
                  className="emptycartimagesection"
                />
                <div className="emptycartcontentsection">
                <h3>Oops, Your Cart is <span>Empty!</span></h3>
                <h5>Your cart is waiting to be filled. Start shopping now!</h5>
                <button  onClick={() =>
                      props.router.navigate("/dashboard/0", { replace: true })
                    }>Go to Orders</button>
                </div>
              </div>
            )}
            {rows.length > 0 && (
              <div className="myorderscontainerdivsection">
                {/* <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(
                    rows.filter((item) =>
                      value
                        ? item.name.toLowerCase().includes(value.toLowerCase())
                        : true
                    ).length / rowsPerPage
                  )}
                  page={page}
                  onChange={handleChangePage}
                  showFirstButton
                  showLastButton
                />
              </Stack> */}
                <Stack
                  spacing={2}
                  className="d-flex justify-content-between align-items-center flex-row"
                >
                  {/* Dropdown for Rows Per Page */}
                  <div className="col-6">
                    <FormControl variant="outlined" size="small">
                      <InputLabel>Rows per page</InputLabel>
                      <Select
                        defaultValue={rowsPerPage}
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        label="Rows per page"
                      >
                        <MenuItem selected={rowsPerPage == 10} value={10}>
                          10
                        </MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <Pagination
                      count={Math.ceil(
                        rows.filter((item) =>
                          value
                            ? item.name
                                .toLowerCase()
                                .includes(value.toLowerCase())
                            : true
                        ).length / rowsPerPage
                      )}
                      page={page}
                      onChange={handleChangePage}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </Stack>
              </div>
            )}
            {rows.length > 0 && (
              <div className="col-lg-6 d-flex justify-content-between align-items-center continueshoppingandemptyshoppingcartsection">
                <div className="viewshoppingcartsection">
                  <h5
                    onClick={() =>
                      props.router.navigate("/dashboard/0", { replace: true })
                    }
                  >
                    <a href>Continue Shopping</a>
                    <span className="nextbuttonicon">
                      <GrFormPreviousLink />
                    </span>
                  </h5>
                </div>
                <div>
                  <button
                    className="nav-button-section"
                    onClick={() => props.dispatch(emptyCart(props.products))}
                  >
                    Empty Shopping Cart
                  </button>
                </div>
              </div>
            )}
            {rows.length > 0 && (
              <div className="col-lg-12 d-flex justify-content-end align-items-center orderdetailssection">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center flex-column">
                  <div className="col-12">
                    <h3>Order Details :</h3>
                  </div>
                  {/* <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                  <p>Receiving Dates:</p>
                  <p>{tomorrowDate}</p>
                </div> */}
                  <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                    <p>Total Line Items:</p>
                    <p>{rows?.length ?? 0}</p>
                  </div>
                  <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                    <p>Total Quantity</p>
                    <p>{getTotalQty()}</p>
                  </div>
                  <div className="col-12 d-flex justify-content-between align-items-center mt-3">
                    <button onClick={initiateOrders} className="button-section">
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    products: state.product.products ?? [],
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Carts));

import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";

function AnalysisChart(props) {
  const [option, setOptions] = useState({});

  const colors = [
    "#F79250", // Red
    "#f9a873", // Blue
    "#F9A873", // Yellow
    "#FAB98F", // Green
    "#FBC7A5", // Purple
    "#FCD2B7", // Orange
    "#FDDBC5", // Hot Pink
    "#FDE2D1", // Medium Sea Green
  ];

  useEffect(() => {
    console.log("sectionasndas")
    let { dayConsumption,dayExpenditure,type } = props;
    let source = [];
    let totalValue= 0;
    if(type==="Consumption"){
      totalValue = dayConsumption.total??0;
    if (dayConsumption.myData) {
      // Sort by percentage in descending order and pick the top 7
      const topData = dayConsumption.myData
        .sort((a, b) => b.percentage - a.percentage)
        .slice(0, 11);

      topData.forEach((i) => {
        source.push([i.id, i.name, i.percentage.toFixed(2),`${Math.round(i.value)} ${i.unit_name}`]); // Format percentage to two decimal places
      });
    }
  }else{
    totalValue= dayExpenditure.total??0;
    if (dayExpenditure.myData) {
      // Sort by percentage in descending order and pick the top 7
      const topData = dayExpenditure.myData
        .sort((a, b) => b.percentage - a.percentage)
        .slice(0, 11);

      topData.forEach((i) => {
        source.push([i.id, i.name, i.percentage.toFixed(2),`₹${Math.round(i.value)}`,""]); // Format percentage to two decimal places
      });
    }
  }

    const val = {
      title:  {
        text: type === "Consumption" ? "" : "Total Expenditure",
        subtext: `${type === "Consumption" ?"":"₹"}${type === "Consumption" ?"":totalValue} ${type === "Consumption" ?"":""}`, // Display the total value
        left: "center", // Position at the top-right corner
        
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
          
        },
        subtextStyle: {
          fontSize: 14,
          color: "#555",
        },
        // padding: [10, 100, 0, 0],
      },
      dataset: [
        {
          dimensions: ["id", "name", "percentage"],
          source: source,
        },
        {
          transform: {
            type: "sort",
            config: { dimension: "percentage", order: "desc" },
          },
        },
      ],
      xAxis: {
        type: "category",
        axisLabel: {show:false },
      },
      yAxis: {
        axisLine: {
          show: true,
        },
      },
      series: {
        type: "bar",
        encode: { x: "name", y: "percentage" },
        datasetIndex: 1,
        itemStyle: {
          color: function (params) {
            return colors[params.dataIndex % colors.length];
          },
        },
        label: {
          show: true,
          position: "inside", 
          formatter: function (params) {
            console.log(params.data); // Log the percentage value
            return `${params.name}\n${params.data[2]}% (${params.data[3]})`; 
          },
          rotate:"90",
          color: "#382E2C", // Set the label color to black
          fontWeight: "normal", // Bold font
          offset: [0, 0], // Adjust the offset to control vertical alignment
        },
      },
    };
    setOptions(val);
  }, [props]);

  return (
    <div className="d-flex justify-content-center mt-5">
      <ReactEcharts option={option} style={{ height: "600px", width: "100%" }} />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    dayConsumption: state.invoice.dayConsumption,
    dayExpenditure: state.invoice.dayExpenditure,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalysisChart)
);

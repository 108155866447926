import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { ContentHeader } from "../../Components/header";
import Logo from "../../assets/logo_header.png";
import LandingLogo from "../../assets/landing_bg.png";

function Landing(props) {
  const nevigateToLogin = () => {
    props.router.navigate("/login");
  };

  return (
    <div className="w-ful landingscreen">
      <ContentHeader />
      <div className="w-100 bg-red login-header d-flex align-items-center sticky-wrapper-header">
        <div className="text-center position-absolute w-100 logoimagedivsection">
          <img src={Logo} width={180} className="logoimagesection" alt="Logo" />
        </div>
        <div className="position-absolute d-flex align-items-center searchiconandloginbuttonsection">
          <div className="searchiconsection">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              fill="#382E2C"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </div>
          <div className="text-end w-100 align-content-center">
            <button onClick={nevigateToLogin} className="button-section">
              LOG IN
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="position-relative flex-grow flex flex-col">
          <img className="w-100 content" src={LandingLogo} alt="Landing" />
          <div className="position-absolute welcome-text text-center">
            <div className="bannertextsection">
              <h3>We make it happen.</h3>
              <h3>Fast, fresh, customized food distribution.</h3>
            </div>
            {/* <button className="button-section" onClick={handleCreateAccount}>
              CREATE A BUSINESS ACCOUNT
            </button> */}
          </div>
        </div>
      </div>
      <div className="productdescription text-center">
        <h3>Why Choose Farmaze ?</h3>
        <p>We provide the freshest vegetables from frame to your business.</p>
        <div className="container commoncontainersection">
          <div className="row">
            <div className="card-columns three-card-columns d-flex align-items-stretch justify-content-between col-lg-12">
              <div className="px-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 individual-three-cards-section">
                <div className="card text-white text-center p-3">
                  <p>Description of why this feature is beneficial.</p>
                </div>
              </div>
              <div className="px-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 individual-three-cards-section">
                <div className="card text-white text-center p-3">
                  <p>Description of why this feature is beneficial.</p>
                </div>
              </div>
              <div className="px-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 individual-three-cards-section">
                <div className="card text-white text-center p-3">
                  <p>Description of why this feature is beneficial.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonialsection text-center">
        <div className="container">
          <div className="row">
            <div className="card-columns d-flex align-items-stretch justify-content-between four-cards-columns-section">
              <div className="px-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 individual-four-cards-columns-section">
                <div className="card text-white text-center p-3">
                  <h3>Name of the customer</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in
                  </p>
                </div>
              </div>
              <div className="px-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 individual-four-cards-columns-section">
                <div className="card text-white text-center p-3">
                  <h3>Name of the customer</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in
                  </p>
                </div>
              </div>
              <div className="px-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 individual-four-cards-columns-section">
                <div className="card text-white text-center p-3">
                  <h3>Name of the customer</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in
                  </p>
                </div>
              </div>
              <div className="px-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 individual-four-cards-columns-section">
                <div className="card text-white text-center p-3">
                  <h3>Name of the customer</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    products: state.product.products,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Landing)
);

import React, { useState } from "react";
import { ContentHeader, Header } from "../../Components/header";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { getClientDetails } from "../../services/Actions/client";
import withRouter from "../Router/withRouter";
import { redirect } from "react-router-dom";
import { API } from "../../services/apis";
import axios from "axios";
// import service from "../../services/Actions/axios_service";
import { toast } from 'react-toastify';
// import https from "https";

function Login(props) {
  const [email, setEmail] = useState(0);
  const [password, setPassword] = useState(0);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  // const [form, setForm] = useState({
  //   username: "crispydosa_admin",
  //   password: "fp4WL797UO8Q",
  // });
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  // const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const onUpdateField = (e) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(nextFormState);
  };
  const login = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let payload = {
        password: form.password,
        username: form.username,
    };
    // localStorage.setItem("token", "token");
    let url = `${API.BASE_URL}${API.login}`;

    axios.post(url, payload, {
        // httpsAgent: new http.Agent({
        //     rejectUnauthorized: false,
        // }),
    })
    .then((res) => {
        let token = res?.data?.access_token ?? "";
        let refreshToken = res?.data?.refresh_token ?? "";

        localStorage.setItem("token", "token");
        props.dispatch(getClientDetails());
        // localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        // service.defaults.headers.post["Authorization"] = `Bearer ${token}`;
        props.router.navigate("/dashboard/0");
    })
    .catch((error) => {
      toast.error(error?.response?.data??"Login failed")
        setError(error?.response?.data ?? "");
    });
};

  return (
    <div className="w-ful loginpagesection" style={{ overflow: "hidden" }}>
      <ContentHeader />
      <Header />
      <div className="row loginpagerowsection">
      <div className="col-lg-6 content-column left-side-content-section">
        <div className="login-form-section">
          <h4 className="login-title mb-3">Welcome Back!</h4>
          <p className="login-subtitle">
            Don't have an account yet?{" "}
            <span>
              <a className="login-reachout-txt" href="#">Reach out</a>
            </span>
          </p>
          <form name="form" onSubmit={login}>
            <div className="form-group">
              <div className="input-group">
                <input
                  className={
                    "form-control login-input mb-4" +
                    (submitted && !form.username ? " has-error" : "")
                  }
                  name="username"
                  placeholder="Email Address"
                  value={form.username}
                  onChange={onUpdateField}
                />
              </div>
              {/* {submitted && !form.username && (
                <div className="help-block text-right">
                  Username is required
                </div>
              )} */}
            </div>
            <div className="form-group">
              <div className="input-group">
                <input
                  type="password"
                  className={
                    "form-control login-input mb-2" +
                    (submitted && !form.password ? " has-error" : "")
                  }
                  name="password"
                  placeholder="Password"
                  value={form.password}
                  onChange={onUpdateField}
                />
              </div>
              {/* {submitted && !form.password && (
                <div className="help-block text-right">
                  Password is required
                </div>
              )} */}
            </div>
            <div className="checkbox-login">
              <Checkbox className="checkbox-css" />
              <p className="m-0">Remember Me</p>
            </div>
            <div className="mt-2">
              <Button type="submit" className="upload-save-btn">
                Log In
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-6 login-image"></div>
    </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));

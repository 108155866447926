import React, { useState, useEffect } from "react";
// import Tab from "@mui/material/Tab";

import { connect } from "react-redux";
import withRouter from "../Router/withRouter";

import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { LuDownload } from "react-icons/lu";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

import { getInvoicess, downloadInvoice } from "../../services/Actions/client";
import DatePicker from "react-datepicker";
import NoItems from "../../Components/no_items";
import CustomRange from "../../Components/custom_range";
import "react-datepicker/dist/react-datepicker.css";
import EmptyInvoice from "../../assets/cart/emptyinvoice.png";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

// function createData(name, unit, quantity, availability) {
//   return { name, unit, quantity, availability };
// }

// const rows = [
//   createData(
//     "FT86578849849",
//     "07/20/2024",
//     <div className="statussection inprogress">
//       IN PROGRESS{" "}
//       <span className="download-icon">
//         <LuDownload />{" "}
//       </span>
//     </div>
//   ),
//   createData(
//     "FT85793903855",
//     "07/03/2024",
//     <div className="statussection delivered">
//       DELIVERED{" "}
//       <span className="download-icon">
//         <LuDownload />{" "}
//       </span>
//     </div>
//   ),
//   createData(
//     "FT90233756389",
//     "06/23/2024",
//     <div className="statussection cancelled">
//       CANCELLED{" "}
//       <span className="download-icon">
//         <LuDownload />{" "}
//       </span>
//     </div>
//   ),
//   createData(
//     "FT68573685830",
//     "06/22/2024",
//     <div className="statussection delivered">
//       DELIVERED{" "}
//       <span className="download-icon">
//         <LuDownload />{" "}
//       </span>
//     </div>
//   ),
// ];

function Invoices(props) {
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [tableFilter, setTableFilter] = useState(7);

  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [openModal, setOpenModal] = useState(false); // State to control modal

  const [search, setSearch] = useState("");

  useEffect(() => {
    // getInvoicesApi("", "");
    callDropDownFilter(tableFilter);
  }, []);

  useEffect(() => {
    // downloadInvoice();
    setInvoices(props.invoices);
  }, [props]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const onSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
  };
  const donloadInvoice = (i) => {
    if (i.status == "approved") {
      props.dispatch(downloadInvoice(i));
    }
  };
  const getInvoicesApi = (from, to) => {
    props.dispatch(getInvoicess(from, to));
  };
  // const handleMonthChange = (date, setDate) => {
  //   setDate(date);
  // };
  // const clearDate = () => {
  //   setStartDate(null);
  //   setEndDate(null);
  // };
  const applyFilter = (date1, date2) => {
    const from = date1.format("YYYY-MM-DD");

    const to = date2.format("YYYY-MM-DD");

    getInvoicesApi(from, to);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when rows per page changes
  };
  const handleOptionsChange = (event) => {
    let val = event.target.value;
    if (val == 0) {
      // handleCloseModal();
      setDefaultDate();
    } else {
      callDropDownFilter(val);
      // setTableFilter(val);
    }
    setTableFilter(val);
  };
  const callDropDownFilter = (val) => {
    const today = moment();
    const laststart = today.clone().subtract(val, "days");
    applyFilter(laststart, today);
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
    // setSelectedOrder(null);
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleMonthChange = (date, setDate) => {
    setDate(date);
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // useEffect(() => {
  //   const today = new Date();
  //   const tomorrow = new Date();
  //   tomorrow.setDate(today.getDate() + 1);

  //   setFromDate(formatDate(today));
  //   setToDate(formatDate(tomorrow));
  // }, []);

  const setDefaultDate = () => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    setFromDate(formatDate(today));
    setToDate(formatDate(tomorrow));
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFromdateChange = (e) => {
    const newFromDate = e.target.value;
    setFromDate(newFromDate);

    if (new Date(newFromDate) >= new Date(toDate)) {
      const updatedTodate = new Date(newFromDate);
      updatedTodate.setDate(updatedTodate.getDate() + 1);
      setToDate(formatDate(updatedTodate));
    }
  };

  const handleTodateChange = (e) => {
    const newToDate = e.target.value;
    setToDate(newToDate);

    if (new Date(newToDate) <= new Date(fromDate)) {
      const updatedFromdate = new Date(newToDate);
      updatedFromdate.setDate(updatedFromdate.getDate() - 1);
      setFromDate(formatDate(updatedFromdate));
    }
  };

  const onClickApply = () => {
    console.log("Date Range", fromDate, toDate);
    applyFilter(moment(fromDate), moment(toDate));
  };
  return (
    <div className="w-full">
      {/* <ContentHeader />
      <Header />
      <Dashboards /> */}
      <div className="container commoncontainer">
        <div className="row">
          <div className="productssection">
            <div className="col-lg-12 d-flex align-items-center justify-content-between invoice-header-section">
              <h3 className="col-lg-6">Invoices</h3>
              <div className="col-lg-2">
                <FormControl variant="outlined" size="medium" fullWidth={true}>
                  {/* <InputLabel>Rows per page</InputLabel> */}
                  <Select
                    value={tableFilter}
                    onChange={handleOptionsChange}
                    sx={{
                      height: 36, // Adjust the height here (e.g., 36px)
                      fontSize: "0.875rem", // Optional: Adjust font size for consistent appearance
                    }}
                    // label="Rows per page"
                  >
                    <MenuItem value={7}>Last 7 days</MenuItem>
                    <MenuItem value={14}>Last 2 weeks</MenuItem>
                    <MenuItem value={30}>Last 30 days</MenuItem>
                    <MenuItem value={0}>Custom Range</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {/* {invoices.length > 0 && (
              <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5 invoicecontainerdivsection">
                <div className="inputseachsection"> */}
            {/* <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search by Date..."
                  aria-label="Search"
                  onChange={onSearch}
                /> */}
            {/* </div>                
              </div>
            )} */}
            {tableFilter == 0 && (
              // <div className="d-flex align-items-center mb-5 fromDateSectionToDateSectionclearandapplysection">
              //   <div className="col-6 d-flex fromDateSectionToDateSection">
              //     <div className="fromDateSection col-6 d-flex align-items-center">
              //       <h5 className="me-3">From</h5>
              //       <DatePicker
              //         selected={startDate}
              //         placeholderText="dd/mm/yyyy"
              //         onChange={(date) => handleMonthChange(date, setStartDate)}
              //         // onYearChange={(date) => handleMonthChange(date)}

              //         dateFormat="dd/MM/yyyy"
              //         // showMonthYearPicker
              //         className="form-control"
              //       />
              //     </div>
              //     <div className="toDateSection col-6 d-flex align-items-center">
              //       <h5 className="me-3">To</h5>
              //       <DatePicker
              //         selected={endDate}
              //         placeholderText="dd/mm/yyyy"
              //         onChange={(date) => handleMonthChange(date, setEndDate)}
              //         // onYearChange={(date) => handleMonthChange(date)}

              //         dateFormat="dd/MM/yyyy"
              //         // showMonthYearPicker
              //         className="form-control"
              //       />
              //     </div>
              //   </div>
              //   <div className="col-6 d-flex clearandapplybuttonsection">
              //     <div className="col-3 d-flex justify-content-start align-items-center">
              //       <button
              //         onClick={clearDate}
              //         className="button-section greyButton"
              //       >
              //         Clear
              //       </button>
              //     </div>
              //     <div className="col-3 d-flex justify-content-start align-items-center">
              //       <button onClick={applyFilter} className="button-section">
              //         Apply
              //       </button>
              //     </div>
              //   </div>
              // </div>
              // <div className="reservation-container">
              //   <div className="date-selection d-flex">
              //     <div className="from-date col-6 d-flex align-items-center">
              //       <h5 className="label me-3">From</h5>
              //       <DatePicker
              //         selected={startDate}
              //         placeholderText="dd/mm/yyyy"
              //         onChange={(date) => handleMonthChange(date, setStartDate)}
              //         dateFormat="dd/MM/yyyy"
              //         className="form-control date-picker"
              //       />
              //     </div>
              //     <div className="to-date col-6 d-flex align-items-center">
              //       <h5 className="label me-3">To</h5>
              //       <DatePicker
              //         selected={endDate}
              //         placeholderText="dd/mm/yyyy"
              //         onChange={(date) => handleMonthChange(date, setEndDate)}
              //         dateFormat="dd/MM/yyyy"
              //         className="form-control date-picker"
              //       />
              //     </div>
              //   </div>
              //   <div className="button-actions col-6 d-flex">
              //     <button
              //       onClick={clearDate}
              //       className="clear-btn button-section"
              //     >
              //       Clear
              //     </button>
              //     <button
              //       onClick={applyFilter}
              //       className="apply-btn button-section"
              //     >
              //       Apply
              //     </button>
              //   </div>
              // </div>
              <div className="reservation-box col-lg-12">
                <div className="static common-div-section">
                    <div className="input-container h-25">
                      <label htmlFor="fromdate">From Date</label>
                      <input
                        type="date"
                        id="fromdate"
                        value={fromDate}
                        onChange={handleFromdateChange}
                      />
                    </div>
                  </div>
                  <div className="flex common-div-section">
                    <div className="input-container h-25">
                      <label htmlFor="todate">To Date</label>
                      <input
                        type="date"
                        id="todate"
                        value={toDate}
                        onChange={handleTodateChange}
                      />
                    </div>
                  </div>
                  <div className="flexSSSS">
                    <button className="button-section greyButton">Clear</button>
                  </div>
                  <div className="flex">
                    <button onClick={onClickApply} className="button-section">
                      Apply
                    </button>
                  </div>
              </div>
            )}
            {invoices.length > 0 ? (
              <TableContainer
                component={Paper}
                className="table-responsive commontablesection"
                style={{ borderRadius: "0px" }}
              >
                <Table
                  className="table table-bordered"
                  style={{ marginBottom: "0px", border: "1px solid black" }}
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Invoice Number</StyledTableCell>
                      <StyledTableCell align="center">
                        Date Placed
                      </StyledTableCell>
                      <StyledTableCell align="center">Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices
                      .filter((item) =>
                        search
                          ? item.order_id
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            item.status
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          : true
                      )
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map((row) => (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.invoice_number}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {/* {moment(row.generated_at).format('MM/DD/YYYY')} */}
                            {row.generated_at}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => donloadInvoice(row)}
                            align="center"
                          >
                            <div className="statussection delivered">
                              {row.status}
                              {row.status == "approved" && (
                                <span className="pl-2 download-icon">
                                  <LuDownload />{" "}
                                </span>
                              )}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="emptycartdivsection">
              <img
                src={EmptyInvoice}
                alt="image"
                className="emptycartimagesection"
              />
              <div className="emptycartcontentsection">
              <h3><span>No Invoices</span> Available</h3>
              <h5>Generate invoices by completing your orders.</h5>
              {/* <button>Go to Orders</button> */}
              </div>
            </div>
            )}
            {invoices.length > 0 && (
              <div className="myorderscontainerdivsection">
                <Stack
                  spacing={2}
                  className="d-flex justify-content-between align-items-center flex-row"
                >
                  {/* Dropdown for Rows Per Page */}
                  <div className="col-6">
                    <FormControl variant="outlined" size="small">
                      <InputLabel>Rows per page</InputLabel>
                      <Select
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        label="Rows per page"
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <Pagination
                      count={Math.ceil(
                        invoices.filter((item) =>
                          search
                            ? item.order_id
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              item.status
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            : true
                        )
                      )}
                      page={page}
                      onChange={handleChangePage}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </Stack>
              </div>
            )}
          </div>
        </div>
      </div>
      <CustomRange open={openModal} handleClose={handleCloseModal} />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    invoices: state.invoice.invoices ?? [],
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Invoices)
);
